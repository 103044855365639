




























































































































/* eslint-disable quotes */

import Vue from 'vue';
import Backbone from '../core/Backbone';
import Component from 'vue-class-component';
import User, { UserState } from '@/models/User';
import moment from 'moment';
import Organisation from '../models/Organisation';
import { State as OrgState } from '@/models/Organisation';
import router from '@/router';
import Customer from '@/models/Address';
import Address from '@/models/Address';

@Component({
	filters: {
		moment: function (date: string) {
			if (date == undefined) {
				return '-';
			}
			return moment(date).format('L');
		},
		momentWithTime: function (date: string) {
			if (date == undefined) {
				return '-';
			}
			return moment(date).format('L LT');
		}
	},
	components: {},
	watch: {}
})
export default class OrgAdmin extends Vue {
	search = '';
	statusMenu = false;
	OrgState = OrgState;
	UserState = UserState;
	orgList = {
		headers: [
			{
				text: '',
				align: 'left',
				sortable: false
			},
			{
				text: 'Organisationer',
				align: 'left',
				sortable: true,
				value: 'Name'
			},
			{
				text: '',
				align: 'left',
				sortable: true,
				value: 'State'
			}
		]
	};

	organisations: Organisation[] = [];
	selectedOrg: Organisation | null = null;
	customerList = {
		headers: [
			{
				text: 'Navn',
				align: 'left',
				sortable: true,
				value: 'DisplayName'
			},
			{
				text: 'Adresse',
				align: 'left',
				sortable: true,
				value: 'FullAddressOneLine'
			}
		],
		items: [] as Address[]
	};
	usersList: User[] = [];

	SortCustomers(items: Customer[], index: string[], isDesc: boolean[]) {
		items.sort((a, b) => {
			if (isDesc[0]) {
				let c = a;
				a = b;
				b = c;
			}

			if (index[0] == 'DisplayName') {
				return a.DisplayName.localeCompare(b.DisplayName);
			} else if (index[0] == 'FullAddressOneLine') {
				let c = (a.ZipAndCity || '').localeCompare(b.ZipAndCity || '');
				if (c != 0) {
					return c;
				}

				return (a.Address || '').localeCompare(b.Address || '');
			}

			return 0;
		});

		return items;
	}

	async mounted() {
		await this.RefreshOrgs();
	}

	private async RefreshOrgs() {
		let response = await Backbone.RemoteActions.Other.GetAllOrganisations();
		if (response.status < 300) {
			this.organisations = response.data;
		}
	}

	async SelectOrg(organisation: Organisation) {
		this.usersList = [];
		let userResponse = await Backbone.RemoteActions.Other.GetOrganisationUsers(organisation.Id || '');

		if (userResponse.status < 300) {
			this.usersList = userResponse.data;
		}

		let customerResponse = await Backbone.RemoteActions.Other.GetOrganisationCustomers(organisation.Id || '');
		if (customerResponse.status < 300) {
			this.customerList.items = customerResponse.data;
		}
		this.selectedOrg = organisation;
	}

	async Login(user: User) {
		let confirm = await Backbone.UI.OpenConfirmDialog(
			'Log ind som anden bruger?',
			'<p>Dette vil logge dig ind som brugeren ' +
				user.Name +
				'</p>Er du sikker?</p><p><i>Du skal logge ind igen som administrator hvis du vil tilbage til denne side!</i></p>',
			true
		);

		if (!confirm) {
			return;
		}

		let response = await Backbone.RemoteActions.Other.LogInAs(user.Email || '');
		if (response.status < 300) {
			Backbone.State.Clear();
			Backbone.RemoteActions.authToken = response.data.Token;

			let r = await Backbone.State.InitState();
			if (r) {
				router.push('/addresslist');
				return;
			}
		}
	}

	private OrgStateAsIcon(organisation: Organisation) {
		switch (organisation.State) {
			case OrgState.Active:
				return '';
			case OrgState.Inactive:
			case OrgState.Deleted:
				return 'mdi-delete-off-outline';
			case OrgState.WatingForConfirmation:
				return 'mdi-email-outline';
			case OrgState.MarkedForDeletion:
				return 'mdi-alert-outline';
		}

		return 'mdi-alert-outline';
	}

	private async DeleteOrganisation(orgId: string) {
		let result = await Backbone.UI.OpenConfirmDialog(
			'Slet organisationen!',
			'Du er ved at slette hele organisation!<br />Alle organisationens data vil blive slettet.' +
				"<br /><br /><span style='text-color: red'>DENNE HANDLING KAN IKKE FORTRYDES!</span>" +
				'<br /><br />Er du helt sikker?',
			true,
			{ color: 'error', width: 400, primaryBtnText: 'Slet organisationen!' }
		);

		if (result != true) {
			return;
		}

		Backbone.UI.Progress.Show('Sletter organisation... ');
		let response = await Backbone.RemoteActions.Other.WipeOrganisationData(orgId);

		if (response.status < 300) {
			this.selectedOrg = null;
			await this.RefreshOrgs();
			Backbone.UI.Progress.Hide();
			return;
		}

		Backbone.UI.Progress.Hide();
		Backbone.UI.PushApiErrorAlert('Kunne ikke slette organisationen', response, 'error', true);
	}

	private async MarkForDeletion(orgId: string) {
		Backbone.UI.Progress.Show('Went... ');
		let response = await Backbone.RemoteActions.Other.MarkOrgForDeletion(orgId);
		Backbone.UI.Progress.Hide();

		if (response.status < 300) {
			await this.RefreshOrgs();
			this.SelectOrg(this.FindOrg(orgId)!);

			return;
		}

		Backbone.UI.PushApiErrorAlert('Fejl på server!', response, 'error', true);
	}

	private async SetToActive(org: Organisation) {
		org.State = OrgState.Active;
		Backbone.UI.Progress.Show('Ændrer organisations status...');

		let response = await Backbone.RemoteActions.Other.PutOrganisation(org);
		Backbone.UI.Progress.Hide();

		if (response.status < 300) {
			await this.RefreshOrgs();
			this.SelectOrg(this.FindOrg(org.Id!)!);
			return;
		}

		Backbone.UI.PushApiErrorAlert('Fejl på server!', response, 'error', true);
	}

	private FindOrg(orgId: string): Organisation | null {
		for (let i = 0; i < this.organisations.length; i++) {
			if (this.organisations[i].Id == orgId) {
				return this.organisations[i];
			}
		}

		return null;
	}

	private Confirm(user: User | undefined) {
		if (!user) {
			user = this.usersList[0];
		}

		if (this.selectedOrg?.State == OrgState.WatingForConfirmation) {
			window.open('confirm?token=' + user!.InviteToken);
		} else {
			window.open('invite?token=' + user!.InviteToken);
		}
	}
}
